import { Stack, Typography } from '@mui/material';
import React from 'react';

import Button from '@mui/material/Button';
import { mainBtnStyle } from 'ui/buttons/buttonsMain';

export const SystemError: React.FC = () => (
  <Stack spacing={5} alignItems="center" height="100vh" justifyContent="center">
    <Typography
      variant="h3"
      sx={{
        color: '#ff6d6d',
      }}
    >
      Произошла непредвиденная ошибка
    </Typography>
    <Button sx={mainBtnStyle(true)} onClick={() => window.location.reload()}>
      Перезагрузить страницу
    </Button>
  </Stack>
);
